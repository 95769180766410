<template>
  <div>
    <a-cascader class="w-full"
                :options="timeOptions"
                placeholder="请选择时间"
                :display-render="displayRender"
                v-model="selectValue" @change="handlerChange" />
  </div>
</template>

<script>
import {
  getDateList,
  getTimeList2,
  formatNumber
} from '@/utils/index.js'

// import {getDaysBetween} from '@/utils/index'
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    maxdays: {
      type: Number,
      // default: 120,
      default: 90,
    }
  },
  data () {
    return {
      timeOptions: [],
      selectValue: [],
    }
  },
  mounted () {
    this.initList(this.value[0])
    
    if(this.value.length == 2){
      const time_arr = this.value[1].split(/[-~]/)
      this.selectValue = [this.value[0], time_arr[0], this.value[1]]
    }else{
      this.selectValue = this.value
    }
  },
  methods: {

    displayRender(param){
      const labels = param.labels
      if(labels && labels.length == 3){
        return labels[0] + " / " + labels[2]
      }
      if(labels){
        return labels.join(" / ")
      }
      return null
    },

    strToDate (str) {
      str = String(str).replace(/-/g, '/');
      return new Date(str)
    },
    handlerChange(){
      if(this.selectValue && this.selectValue.length == 3){
        this.$emit('update:value', [this.selectValue[0], this.selectValue[2]])
        return
      }
      this.$emit('update:value', this.selectValue)
    },

    initList(midDate){
      // 如果有蛋糕
      //       若不含蛋糕：
      // 1）今天明天内、不能超出营业结束时间范围
      // 2）必须在“下单时间”之后
      // 若含蛋糕：
      // 1）30天内、不能超出营业结束时间范围
      // 2）必须在“下单时间+蛋糕制作时间”之后，其中蛋糕制作时间是蛋糕的一个属性（单位h，大部分蛋糕是3h、个别蛋糕

      // 配送时间范围
      let startTime = '08:00'
      let endTime = '23:59'

      if(midDate){
        const now_t = new Date().getTime()
        let midD = new Date(midDate)
        if(midD.getTime() > now_t){
          const new_t = Math.max(midD.getTime() - 30*24*3600*1000, now_t)
          midD.setTime(new_t)
        }
        midDate = midD.getFullYear() + "-" + formatNumber(midD.getMonth() + 1) + "-" + formatNumber(midD.getDate());
      }else{
        const now = new Date();
        midDate = now.getFullYear() + "-" + formatNumber(now.getMonth() + 1) + "-" + formatNumber(now.getDate());
      }

      let tsStart0     = this.strToDate(midDate + " 00:00:00").getTime();
      let tsStartStart = this.strToDate(midDate + " " + startTime).getTime();
      let tsStartEnd   = this.strToDate(midDate + " " + endTime).getTime();

      // 时间picker
      let optsDateList = getDateList(this.maxdays, new Date(midDate));

      let allTimeOpts2 = getTimeList2(tsStart0, {
        start:  tsStartStart,
        end:    tsStartEnd,
        // delta: this.delta,
      });
      let timeStartList = allTimeOpts2.map(item => {
        return {
          label: item.name,
          value: item.start,
          // value: item.start + '-' + item.end,
          children: item.children.map(el=>{
            return {
              label: el.name,
              value: el.start + '-' + el.end,
            }
          })
        }
      })

      this.timeOptions = optsDateList.map(date => {
        // 如果回显的值在选项里没有：就拼接上去
        if(date.value == this.value[0] && this.value && this.value.length == 2){
          const hasTime = timeStartList.some(start=>{
            return start.children.some(el=>{
              return el.value == this.value[1]
            })
          })
          if(!hasTime){
            const time_arr = this.value[1].split(/[-~]+/)
            const time_s = time_arr[0]
            const originItem = timeStartList.find(start=>start.value == time_s)
            const hasStart = !!originItem
            let timeItem = hasStart ? {
              label: originItem.label,
              value: originItem.value,
              children: originItem.children.map(t=>t)
            } : {
              label: time_s,
              value: time_s,
              children: []
            }
            timeItem.children.unshift({
              label: this.value[1],
              value: this.value[1],
            })

            if(!hasStart){
              const timeList2 = [timeItem].concat(timeStartList)
              return {
                label: date.name,
                value: date.value,
                children: timeList2
              }
            }
          }
        }

        return {
          label: date.name,
          value: date.value,
          children: timeStartList
        }
      })
    },
  },
  watch:{
    value(){
      if(this.value.length > 0){
        this.initList(this.value[0])
        if(this.value.length == 2){
          const time_arr = this.value[1].split(/[-~]/)
          this.selectValue = [this.value[0], time_arr[0], this.value[1]]
        }else{
          this.selectValue = this.value
        }
      }
    }
  }

}
</script>

<style>
</style>