import ajax from '@/utils/ajax.js'

/////////////////////   创建订单所需接口   ////////////////////
/**
 * 获取配送网点列表
 * @param {*} params 
 * @returns 
 */
export function getDeliveryOutletsList(params) {
  return ajax.post('/CustomerServiceCreateOrder/deliveryCoOutletsList',params)
}

/**
 * 获取自提网点列表
 * @param {*} params 
 * @returns 
 */
export function getPickUpOutletsList(params) {
  return ajax.post('/CustomerServiceCreateOrder/pickUpCoOutletsList',params)
}


/**
 * 根据网点ID获取商品列表
 * @param {*} params 
 * @returns 
 */
 export function getGoodsListByOutletsId(params) {
  return ajax.post('/CustomerServiceCreateOrder/getOutletsGoodsList',params)
}


/**
 * 获取订单价格
 * @param {*} params 
 * @returns 
 */
 export function getOrderPrice(params) {
  return ajax.post('/CustomerServiceCreateOrder/priceCalculation',params)
}

/**
 * 下单
 * @param {*} params 
 * @returns 
 */
export function createOrder(params) {
  return ajax.post('/CustomerServiceCreateOrder/submit',params)
}

/**
 * 支付相关
 * @param {*} params 
 * @returns 
 */
export function setOrderPayType(params) {
  return ajax.post('/CustomerServiceOrderPay/pay',params)
}
