<template>
  <div class="inline-flex ml-2">
    <a-select default-value="1" :disabled="isDisabeld"
              style="width:100px"
              v-model="selectValue1">
      <a-select-option :value="0">无</a-select-option>
      <a-select-option :value="1">数字蜡烛</a-select-option>
      <a-select-option :value="2">线蜡烛</a-select-option>
    </a-select>
    <template v-if="selectValue1 === 1">
      <a-input style="width:80px;" :disabled="isDisabeld" placeholder="数字"
               v-model="number"
               oninput="value=value.replace(/[^0-9]/g,'').substr(0,3)"></a-input>
    </template>
    <a-select v-if="selectValue1 === 2" :disabled="isDisabeld"
              default-value="1"
              style="width:80px;"
              v-model="selectValue2">
      <a-select-option :value="1">一盒</a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  props:{
    value:{
      type: String,
      default: ''
    },
    isDisabeld: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      selectValue1: 1,
      selectValue2: 1,
      number: '',
    }
  },
  mounted () {
    if (this.value) {
      this.selectValue1 = Number(this.value.split(':')[0])
      this.number = this.value.split(':')[1]
    }else{
      this.selectValue1 = 0
    }
  },
  methods: {
    onChange(){
      let val = ""
      if(this.selectValue1 == 1){
        if(this.number){
          val = this.selectValue1 + ':' + this.number
        }
      }else if(this.selectValue1 == 2){
        val = this.selectValue1 + ':' + this.selectValue2
      }
      this.$emit('update:value', val)
    },
  },
  watch: {
    selectValue1 () {
      this.onChange()
    },
    number () {
      this.onChange()
    }
  }
}
</script>

<style>
</style>